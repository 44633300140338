import React from 'react';
import {PageHero} from '../../components/page-hero';
import Layout from '../../components/layout/layout';
import Section from '../../components/section';

import TopSection from './top-section';
import MiddleSection from './middle-section';
import hexomaticVSAll from '../../images/pricing-screenshots/hexomatic-vs-all.png';

import './styles.scss';

const PremiumCredits = () => {
  return (
    <div className="content-wrapper premium-main-wrapper">
      <Layout>
        <PageHero title="Premium credit add-on" />
        <TopSection />
        <div className="custom-hr my-5"></div>
        <MiddleSection />
        <Section lightBlue className='py-5'>
          <div className="row">
            <div className="col-lg-12 justify-content-center hexomatic-advantages text-center">
              <h3 className="f_size_30 l_height45 f_700 mb_20 ">
                Pay for one subscription to get 1 click access to multiple services right inside your workflow
              </h3>

              <p className="pr_100 pl_100 py-3">
                Say goodbye to expensive subscriptions and take advantage of world class AI services on a convenient and
                cost effective per usage basis using premium credits.
              </p>
            </div>
            <div className="col-lg-12  text-center d-flex align-items-center justify-content-center">
              <img
                style={{maxWidth: '980px', width: '100%'}}
                src={hexomaticVSAll}
                alt="hexomatic advantages comaret to other companies"
              />
            </div>
          </div>
        </Section>
      </Layout>
    </div>
  );
};

export default PremiumCredits;
